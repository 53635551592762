import React from 'react'
import useGlobalStore from '../../store/global'

function Craving() {
    const toggleIsSignupOpen = useGlobalStore(state => state.toggleIsSignupOpen);

    return (
        <>
            <section className="section__grad pb-0">
                <div className="overflow-hidden container position-relative">
                    <div className="row align-items-stretch justify-content-between">
                        <div data-aos="fade-right" className="pt-70 pb-5 col-md-7 position-relative">
                            <h1 className="title-small text-cc-dark mb-4">
                                Where are<br /> you going to <span className="text-cc"><i className="fa-solid fa-question"></i></span>
                            </h1>
                            <p className="lead">
                            We cover you everywhere, Naija, UK, US, everywhere.
                            </p>
                            <button onClick={toggleIsSignupOpen} className="btn cta btn-primary mt-3 shadow-sm font-18"> Get started
                            </button>
                        </div>
                        <div data-aos="fade-left" className="col-md-5">
                            <img style={{ borderRadius: "12px", height: "100%", objectFit: "cover" }} src="assets/images/ride.jpg" width="100%" alt="" />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="count-strip">
                        <h3 className="title text-center mb-5 text-white">Our Achievements</h3>
                        <div className="d-flex flex-wrap mt-3 justify-content-between align-items-center">
                            <div className="box__item">
                                <h1 className="title title--bigger text-white">
                                    20000 <span><i className="fa-solid fa-plus"></i></span>
                                </h1>
                                <p className="text-white">Rides</p>
                            </div>
                            <div className="box__item">
                                <h1 className="title title--bigger text-white">
                                    12000 <span><i className="fa-solid fa-plus"></i></span>
                                </h1>
                                <p className="text-white">Drivers</p>
                            </div>
                            <div className="box__item">
                                <h1 className="title title--bigger text-white">
                                    25000 <span><i className="fa-solid fa-plus"></i></span>
                                </h1>
                                <p className="text-white">Subscriptions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Craving