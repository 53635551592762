export const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(+value)
  };
  
  export const parseJsonString = value => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  export function acronym(value){
    var str = value;
    var matches = str.match(/\b(\w)/g);
    var acronym = matches.join("");
    return acronym
  }
  
  export function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  export function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
  }