const RidePlansDetails = [
  {
    id: "test plan",
    plan_amount: "3000",
    monthly_subscription_fee: "1000",
    discount: 2.5,
    package: "Test Plan",
    backgroundImage: "/assets/images/car-dark-1.png",
    oneOff: "true"
  },
  {
    id: "standard plan",
    plan_amount: "20000",
    monthly_subscription_fee: "1000",
    discount: 2.5,
    package: "Standard Plan",
    backgroundImage: "/assets/images/car-dark-2.png",
  },
  // {
  //   id: "classic plan",
  //   plan_amount: "30000",
  //   monthly_subscription_fee: "1000",
  //   discount: 2.5,
  //   package: "Classic Plan",
  //   backgroundImage: "/assets/images/car-dark-3.png",
  // },
  // {
  //   id: "premium plan",
  //   plan_amount: "50000",
  //   monthly_subscription_fee: "2000",
  //   discount: 5,
  //   package: "Premium Plan",
  //   backgroundImage: "/assets/images/car-dark-2.png",
  // },
  // {
  //   id: "deluxe plan",
  //   plan_amount: "75000",
  //   monthly_subscription_fee: "3000",
  //   discount: 5,
  //   package: "Deluxe Plan",
  //   backgroundImage: "/assets/images/car-dark-1.png",
  // },
  // {
  //   id: "executive plan",
  //   plan_amount: "100000",
  //   monthly_subscription_fee: "5000",
  //   discount: 5,
  //   package: "Executive Plan",
  //   backgroundImage: "/assets/images/car-dark-3.png",
  // },
];
export default RidePlansDetails;
