import React, { useState, useEffect } from "react";
import { useGetVirtualAccountQuery } from "../../../api/ride.js";
import AccountDetails from "./AccountDetails.jsx";
import ConfirmTransfer from "./ConfirmTransfer.jsx";
import {
  useUpdateActiveRideUserMutation,
  useUpdateRideUserMutation,
  useCreateTestRideMutation,
} from "../../../api/ride.js";
import useSignupStore from "../../../store/signup.js";
import Loader from "../Loader";

const PayViaTransferModal = ({
  onClose,
  amount,
  full_name,
  phone,
  onDone,
  oldAmount,
  discount,
}) => {
  const { data, updateData } = useSignupStore((state) => state);

  const { mutateAsync: updateRideUser, isLoading: isUpdateRideUserLoading } =
    useUpdateRideUserMutation();

  const { mutateAsync: createTestRide, isLoading: isCreateTestRideLoading } =
    useCreateTestRideMutation();

  const {
    mutateAsync: updateActiveRideUser,
    isLoading: isUpdateActiveRideUserLoading,
  } = useUpdateActiveRideUserMutation();

  const [view, setView] = useState("account");
  const {
    data: account,
    isLoading: isGetVirtualAccountLoading,
    refetch,
  } = useGetVirtualAccountQuery({
    amount,
    full_name,
    phone,
  });

  useEffect(() => {
    if (amount < 500) {
      handleSkip();
    }
  }, []);

  const handleSkip = async () => {
    try {
      const payload_three = {
        phone: data.user.phone,
      };
      await createTestRide(payload_three);
      onDone();
    } catch (e) {
      console.log({ e });
    }
  };

  const handleConfirmTransferDone = async () => {
    // onDone(account.flw_ref);
    try {
      const payload = {
        phone: data.user.phone,
        plan_amount: data.plan.plan_amount,
        type: "outright",
      };
      const res = await updateRideUser(payload);
      updateData({
        user: {
          ...data.user,
          name: res.data.data.name,
        },
        plan_amount: res.data.data.plan_amount,
      });

      const payload_two = {
        status: "active",
        id: data?.ride_data?.id,
      };
      await updateActiveRideUser(payload_two);
      onDone();
    } catch (e) {
      console.log({ e });
    }
  };
  return (
    <>
      {isCreateTestRideLoading ? (
        <Loader
          header={"Creating your plan"}
          subText={"Please wait while we set you up"}
        />
      ) : (
        <>
          {isGetVirtualAccountLoading ? (
            <>
              <br />
              <br />
              <br />
              <br />
              <div className="d-flex flex-column justify-content-center align-items-center text-center py-5">
                <div className="spinner-grow text-dark" role="status">
                  <span className="sr-only text-cc-dark">Loading...</span>
                </div>
                <div className="font-17 mt-4 text-cc-dark">Please wait..</div>
              </div>
            </>
          ) : (
            <>
              {isUpdateActiveRideUserLoading || isUpdateRideUserLoading ? (
                <Loader
                  header={"Creating your plan"}
                  subText={"Please wait while we set you up"}
                />
              ) : (
                <>
                  {view === "account" && (
                    <AccountDetails
                      account={account}
                      amount={amount}
                      onConfirm={() => setView("confirm")}
                      onCancel={onClose}
                      onGenerate={() => refetch()}
                      oldAmount={oldAmount}
                      discount={discount}
                      onSkip={handleConfirmTransferDone}
                    />
                  )}
                  {view === "confirm" && (
                    <ConfirmTransfer
                      transaction_reference={account.flw_ref}
                      onDone={handleConfirmTransferDone}
                      onCancel={() => setView("account")}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PayViaTransferModal;
