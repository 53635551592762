import React from "react";
import useSignupStore from "../../../store/signup";
import { formatCurrency, numberWithCommas } from "../../../lib/utils";

function PlanDetails({ onBack, onNext }) {
  const { data } = useSignupStore((state) => state);

  const handleNext = () => {
    if (data?.plan.plan_amount === "3000") return onNext("not-registered");
    onNext("options");
  };

  return (
    <>
      <div>
        <button
          style={{ marginBottom: "0px" }}
          className="back"
          type="button"
          onClick={onBack}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-angle-left"
            ></i>
          </span>
        </button>
      </div>
      <div className="pt-70 pb-3">
        <p className="redirect-text font-30 text-deep-blue font-weight-bold mb-2">
          {data.plan.package}
        </p>
        <p className="text-cc-dark font-20 mb-4">
          {" "}
          ₦{numberWithCommas(data.plan.plan_amount)}
        </p>
        {data?.plan.plan_amount === "3000" && (
          <div>
            <hr />
            <li className="text-cc-dark font-18 mb-2">
              This is just a test plan to get started.
            </li>
            <li className="text-cc-dark font-18 mb-2">
              Pay back before end of month
            </li>
            <li className="text-cc-dark font-18 mb-2">
              Move to higher plan as you repay
            </li>
            <li className="text-cc-dark font-18 mb-2">Attracts a ₦500 fee.</li>

            <button
              onClick={handleNext}
              className="mt-4 font-17 d-flex call-number btn btn-blue"
            >
              Choose Plan
            </button>
          </div>
        )}
        {data?.plan.plan_amount !== "3000" && (
          <div>
            <hr />
            <li className="text-cc-dark font-18 mb-2">
              Enjoy ₦{numberWithCommas(data.plan.plan_amount)} worth of rides
              monthly
            </li>
            <li className="text-cc-dark font-18 mb-2">
              You can pay now or pay later.
            </li>
            <li className="text-cc-dark font-18 mb-2">
              ₦{numberWithCommas(data.plan.monthly_subscription_fee)} fee on pay
              later.
            </li>
            <li className="text-cc-dark font-18 mb-2">
              {data.plan.discount}% discount on paid rides.
            </li>
            <li className="text-cc-dark font-18 mb-2">
              Get bonus airtime and data
            </li>

            <button
              onClick={handleNext}
              className="mt-4 font-17 d-flex call-number btn btn-blue"
            >
              Choose Plan
            </button>
          </div>
        )}

        <div
          style={{ width: "70%", right: "-1rem" }}
          className="illustration-image"
        >
          <img src="/assets/images/car-ride.png" alt="" />
        </div>
      </div>
    </>
  );
}

export default PlanDetails;
