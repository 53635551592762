import { useMutation, useQuery } from "react-query";
import http from "../lib/http.js";

export const useCheckRideUserMutation = () => {
  const { mutate, mutateAsync, isLoading } = useMutation((payload) => {
    return http.post(
      "https://sellbackend.creditclan.com/merchantclan/public/index.php/api/rider",
      payload
    );
  });
  return { mutate, mutateAsync, isLoading };
};
export const useCheckRideLoanRequestMutation = () => {
  const { mutate, mutateAsync, isLoading } = useMutation((payload) => {
    return http.post(
      "https://sellbackend.creditclan.com/merchantclan/public/index.php/api/rider/request",
      payload
    );
  });
  return { mutate, mutateAsync, isLoading };
};

export const useUpdateRideUserMutation = () => {
  const { mutate, mutateAsync, isLoading } = useMutation((payload) => {
    return http.post(
      "https://sellbackend.creditclan.com/merchantclan/public/index.php/api/rider/plan",
      payload
    );
  });
  return { mutate, mutateAsync, isLoading };
};

export const useCreateTestRideMutation = () => {
  const { mutate, mutateAsync, isLoading } = useMutation((payload) => {
    return http.post(
      "https://sellbackend.creditclan.com/merchantclan/public/index.php/api/rider/test",
      payload
    );
  });
  return { mutate, mutateAsync, isLoading };
};


export const useUpdateActiveRideUserMutation = () => {
  const { mutate, mutateAsync, isLoading } = useMutation((payload) => {
    return http.patch(
      `https://sellbackend.creditclan.com/merchantclan/public/index.php/api/request/${payload.id}/rider`,
      payload
    );
  });
  return { mutate, mutateAsync, isLoading };
};

export const useCancelRideRequestMutation = () => {
  const { mutate, mutateAsync, isLoading } = useMutation((id) => {
    return http.delete(
      `https://sellbackend.creditclan.com/merchantclan/public/index.php/api/rider/request/${id}/delete`
    );
  });
  return { mutate, mutateAsync, isLoading };
};

export const useGetPlanBalanceMutation = () => {
  const { mutate, mutateAsync, isLoading } = useMutation((phone) => {
    return http.post(
      "https://wasapnodeserver.herokuapp.com/loan_balance",
      { phone },
      {
        headers: {
          "x-api-key":
            "WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228",
        },
      }
    );
  });
  return { mutate, mutateAsync, isLoading };
};

export const useUploadImageMutation = () => {
  const { mutate, mutateAsync, isLoading } = useMutation((file) => {
    const fd = new FormData();
    fd.append("file", file, "avatar.png");
    return http.post("https://mobile.creditclan.com/api/v3/upload/image", fd, {
      headers: {
        "x-api-key":
          "WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228",
      },
    });
  });
  return { mutate, mutateAsync, isLoading };
};

export const useGetVirtualAccountQuery = ({ amount, phone, full_name }) => {
  return useQuery(
    ["account"],
    async () => {
      const res = await http.post(
        "https://wema.creditclan.com/generate/account",
        {
          amount,
          phone,
          full_name,
          merchant_name: "Clan africa",
          narration: "Ride payment",
        }
      );
      return res.data.data;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false, staleTime: Infinity }
  );
};

export const useVerifyTransactionMutation = () => {
  return useMutation(async ({ transaction_reference }) => {
    const res = await http.post(
      "https://wema.creditclan.com/api/v3/wema/verify_transaction",
      {
        transaction_reference,
      }
    );
    return res.data;
  });
};
