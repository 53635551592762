import React from "react";
import useGlobalStore from "../../store/global";

function Benefits() {
  const toggleIsSignupOpen = useGlobalStore(state => state.toggleIsSignupOpen);

  return (
      <>
          <section style={{ backgroundColor: "#f3efe6" }} className=" position-relative overflow-hidden">
              <div className="container">
                  <div className="area">
                      <ul className="circles">
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                      </ul>
                  </div>
                  <div className="row align-items-center">
                      <div className="col-md-7">
                          <img className="mobile-phone" data-aos="zoom-in-right" src="/assets/images/flight.png" width="100%" alt="" />
                      </div>
                      <div data-aos="zoom-in-left" className="pt-5 col-md-5 position-relative">
                          <h2 className="title-small mb-4">
                              Benefit of subscription riding
                          </h2>
                          <div className="snapshot">
                              <ul data-aos="fade-right" className="dotTwo text-black aos-init aos-animate">
                                  <li>
                                      <p className="lead">Get massive discount
                                      </p>
                                  </li>
                                  <li>
                                      <p className="lead">Ride even if you dont have funds</p>
                                  </li>
                                  <li>
                                      <p className="lead">Allign payment with your salary
                                      </p>
                                  </li>
                                  <li>
                                      <p className="lead">Emergency rides handled forever
                                      </p>
                                  </li>
                              </ul>
                          </div>
                          <button onClick={toggleIsSignupOpen} className="btn btn-primary mt-3 shadow-sm font-18"> Set me up
                          </button>
                      </div>
                  </div>
              </div>
          </section>
      </>
  )
}

export default Benefits;
