import React from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";

function LinkedinLogin({onBack, onNext}) {
  const { linkedInLogin } = useLinkedIn({
    clientId: "77a41ahehgi63x",
    redirectUri: `https://freeride.clan.africa/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: () => {
      onNext()
    },
    onError: (error) => {
      console.log(error);
    },
  });
  return (
    <>
      <div>
        <button
          style={{ marginBottom: "0px" }}
          className="back"
          type="button"
          onClick={onBack}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-angle-left"
            ></i>
          </span>
        </button>
      </div>
      <img
        className="pt-70 pb-3"
        src="/assets/images/linkedin.svg"
        style={{ width: "50%" }}
        alt=""
      />
      <div className="pb-3">
        <p className="redirect-text font-30 text-deep-blue font-weight-bold mb-2">
          Please log into your Linkedin account
        </p>
        <p className="text-cc-dark font-17 mb-2">
          {" "}
          Only individuals with LinkedIn accounts are eligible to enjoy this offer
        </p>
      </div>
      <button onClick={linkedInLogin} className="btn btn-blue mr-4 font-17">
        Sign in to Linkedin
      </button>
    </>
  );
}

export default LinkedinLogin;
