import React, { useEffect, useState } from 'react'
import Phone from '../sign-up/Phone';
import Register from '../sign-up/register/Register';
import Registered from '../sign-up/registered/Registered';
import PlanExpired from '../sign-up/PlanExpired'
import RenewPlan from '../sign-up/RenewPlan';
import Success from '../sign-up/Success';
import Outright from '../sign-up/outright/Outright';
import PlanDetails from '../sign-up/Menu/PlanDetails';
import Options from '../sign-up/Menu/Options';
import RidePlans from '../sign-up/Menu/RidePlans';
import NoPlan from '../sign-up/NoPlan';
import WhatsappPrompt from '../sign-up/registered/WhatsappPrompt';
import ActivePlan from '../sign-up/ActivePlan';

function WhatsappSlideout({ isActive, handleToggle }) {
    const [view, setView] = useState("ride-plans");

    useEffect(() => {
        if (isActive) document.scrollingElement.style.overflow = "hidden"
        else document.scrollingElement.style.overflowY = "auto"
    }, [isActive])

    return (
        <>
            <div onClick={handleToggle} className={`main-overlay ${isActive === true ? 'visible' : ''}`}></div>
            <div className={`dotted-bg sidebar ${isActive ? 'active' : ''}`}>
                {
                    view === "ride-plans" &&
                    <RidePlans handleToggle={handleToggle} onNext={(v) => setView(v)}/>
                }
                {
                    view === "plan-details" && 
                    <PlanDetails onNext={(v) => setView(v)} onBack={() => setView("ride-plans")} />
                }
                {
                    view === "options" && 
                    <Options onBack={() => setView("plan-details")} onNext={(v) => setView(v)}/>
                }
                {
                    view === "not-registered" && (
                        <Register onBack={(v) => setView(v)} onNext={(v) => setView(v)} onDone={(v) => setView(v)}/>
                    )
                }
                {
                    view === "outright" && (
                        <Outright onBack={() => setView("options")} onNext={(v) => setView(v)} onDone={(v) => setView(v)} handleToggle={handleToggle}/>
                    )
                }
                {
                    view === "phone" && (
                        <Phone
                            onBack={() => setView("ride-plans")}
                            onNext={(v) => setView(v)} />
                    )
                }
                {
                    view === "no-plan" && (
                        <NoPlan onBack={() => setView("phone")}  onNext={() => setView("ride-plans")}/>
                    )
                }
                {
                    view === "plan-expired" && (
                        <PlanExpired
                            onBack={() => setView("phone")} />
                    )
                }
                {
                    view === "renew-plan" && (
                        <RenewPlan
                            onBack={() => setView("phone")}
                            onNext={(v) => setView(v)} />
                    )
                }
                {
                    view === "active-plan" && <ActivePlan onBack={() => setView("phone")} onDone={() => setView("whatsapp-prompt")}/>
                }
                {
                    view === "whatsapp-prompt" && (
                        <WhatsappPrompt onBack={() => setView("active-plan")}/> 
                    )
                }
            </div>
        </>
    )
}

export default WhatsappSlideout