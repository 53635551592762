import React from "react";
import useGlobalStore from "../../store/global";

function WhyUs() {
  const toggleIsSignupOpen = useGlobalStore(
    (state) => state.toggleIsSignupOpen
  );

  return (
    <>
      <section className="overflow-hidden dotted-bg bg-ride">
        <div className="container position-relative">
          <div className="row">
            <div
              data-aos="fade-left"
              className="pt-100 md-pt-70 col-md-5 position-relative offset-md-7"
            >
              <h1 className="title-small text-cc-dark mb-4">
                Why Ride now,
                <br />
                subscribe now
                <span className="text-cc-dark">
                  <i className="fa-solid fa-question"></i>
                </span>
              </h1>
              <p className="lead">
                You get the convenience of all the best ride hailing platforms
                at your finger tips: Bolt, Uber, Indrive,
                etc. All you need to do is choose a plan and get started for
                pay now or later
              </p>
              <button
                onClick={toggleIsSignupOpen}
                className="btn cta btn-primary mt-3 shadow-sm font-18"
              >
                {" "}
                Get started{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyUs;
