import React from "react";
import { numberWithCommas } from "../../lib/utils.js";
import useSignupStore from "../../store/signup.js";
import useGlobalStore from "../../store/global";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function ActivePlan({ onBack, onDone }) {
  const toggleIsSignupOpen = useGlobalStore(
    (state) => state.toggleIsSignupOpen
  );
  const { data } = useSignupStore((state) => state);
console.log(data)
  return (
    <>
      <div>
        <button
          style={{ marginBottom: "0px" }}
          className="back"
          type="button"
          onClick={onBack}
          //   disabled={isRentRequestLoading}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-angle-left"
            ></i>
          </span>
        </button>
      </div>
      <div className="pt-70 pb-3">
        <h2 className="text-deep-blue font-weight-bold font-30 redirect-text">
          This user is already subscribed to an active plan.
        </h2>
      </div>
      {/* <div className="font-17">
        <ul className="list-group text-cc-dark">
          <li className="list-group-item d-flex justify-content-between align-items-center">
            Customer name:
            <span className="font-weight-600 text-right">
              {data?.user?.name}
            </span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            Ride Plan amount:
            <span className="font-weight-600 text-right">
              ₦{numberWithCommas(data?.ride_data?.monthly_limit)}
            </span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            Ride Plan expiry date:
            <span className="font-weight-600 text-right">
              {data?.ride_data?.expiry_date}
            </span>
          </li>
          {data?.user?.bolt_phone && (
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Bolt phone number:
              <span className="font-weight-600 text-right">
                {data?.user?.bolt_phone}
              </span>
            </li>
          )}
        </ul>
      </div> */}
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Swiper
            slidesPerView={1.5}
            spaceBetween={40}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              470: {
                slidesPerView: 1.3,
                spaceBetween: 20,
              },
              760: {
                slidesPerView: 1.3,
                spaceBetween: 20,
              },
              1100: {
                slidesPerView: 1.2,
                spaceBetween: 20,
              },
            }}
            className="showcase"
          >
            <div className="swiper-pagination"></div>
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <SwiperSlide>
                  <div
                    // onClick={submit}
                    data-wow-delay="0.1s"
                    className="feature-box summary-card mb-4 feature-box-rounded"
                  >
                    <div className="pt-3">
                      <p className="font-30 mb-0 font-weight-bold">{data?.ride_user?.name}</p>
                      <p className="font-17 text-white pt-4 d-flex justify-content-lg-between align-items-lg-center flex-column flex-lg-row align-items-start mb-0 pb-2">
                        Plan balance:
                        <span> ₦{numberWithCommas(data?.ride_data?.balance)}</span>
                      </p>
                      <p className="font-17 text-white d-flex justify-content-lg-between align-items-lg-center flex-column flex-lg-row align-items-start mb-0 pb-2">
                        Total rides:
                        <span> {data?.ride_data?.total_rides} rides</span>
                      </p>
                      <p className="font-17 text-white d-flex justify-content-lg-between align-items-lg-center flex-column flex-lg-row align-items-start mb-0 pb-2">
                        Expiry date:
                        <span> {data?.ride_data?.data?.expiry_date}</span>
                      </p>
                    </div>
                    <div className="active-ribbon">
                    <p>Active</p>
                  </div>
                    <div className="icon"></div>
                  </div>
                </SwiperSlide>
              </div>
            </div>
          </Swiper>
        </div>
      </div>
      <div className="mt-4 d-flex align-items-center">
        <p className="text-cc-dark font-17 redirect-text mb-0">
          Pay for a ride?
        </p>
        <button
          onClick={() => onDone("whatsapp-prompt")}
          className="alt-plan font-17"
        >
          ride now
        </button>
      </div>
    </>
  );
}

export default ActivePlan;
