import React from "react";
import FormInput from "../../home/FormInput.jsx";
import { useForm } from "react-hook-form";
import useSignupStore from "../../../store/signup.js";
import {
  useCheckRideUserMutation,
  useCheckRideLoanRequestMutation,
} from "../../../api/ride.js";

const UserDetails = ({ onNext, onBack }) => {
  const { data, updateData } = useSignupStore((state) => state);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...data?.user, phone: data?.user?.phone ?? data?.bolt_phone  },
  });

  const { mutateAsync: checkRideUser, isLoading: isCheckRideUserLoading } =
    useCheckRideUserMutation();
  const {
    mutateAsync: checkRideLoanRequst,
    isLoading: isCheckRideLoanRequestLoading,
  } = useCheckRideLoanRequestMutation();

  const submit = async (values) => {
    try {
      const payload = {
        name: values.name,
        phone: values.phone,
        email: values.email,
        bolt_phone: data?.bolt_phone
      };
      const res = await checkRideUser(payload);
      if (!!res?.data?.data) {
        updateData({user: {...data.user, ...values}})
        updateData({ride_user: {...data.user, name: res.data.data.name, bolt_phone: res.data.data.bolt_phone}})
        const payload_two = {
          rider_id: res?.data?.data?.id,
          amount: data.plan.plan_amount,
          discount: data.plan.discount
        };
        const res_two = await checkRideLoanRequst(payload_two);
        if(res_two?.data?.status){
          updateData({user: {...data.user, ...values}})
          updateData({ride_data: res_two.data.data})
          if (res_two.data.data.amount === "3000") return onNext("launchpay")
          onNext("check-eligibility")
        }
        else{
          if(res_two?.data?.message === "pending request exists"){
            updateData({user: {...data.user, ...values}})
            updateData({ride_data: res_two.data.data})
            if(res_two.data.data.amount === data.plan.plan_amount){
              if(res_two.data.data.amount === "3000") return onNext("launchpay")
              onNext("check-eligibility")
            } 
            else{
              onNext("pending-request")
            }
          }
          else if(res_two?.data?.message === "active plan exists"){
            updateData({user: {...data.user, ...values}})
            updateData({ride_data: res_two.data})
            onNext("active-plan")
          }
        }
      }
    } catch (e) {
      console.log({ e });
    }
  };

  return (
    <>
      <div>
        <button
          style={{ marginBottom: "0px" }}
          className="back"
          type="button"
          onClick={onBack}
          disabled={(isCheckRideUserLoading || isCheckRideLoanRequestLoading)}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-angle-left"
            ></i>
          </span>
        </button>
      </div>
      <div className="pt-70 pb-5">
        <p className="redirect-text font-30 text-deep-blue font-weight-bold mb-1">
        Let's get to know you
        </p>
        <p className="text-cc-dark font-17">
          Please provide the following information to proceed
        </p>
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <FormInput
          type="text"
          label="Full name"
          {...register("name", {
            required: true,
          })}
        />

        <p
          className={`redirect-text text-white bg-danger error-text font-17 pb-0 ${
            errors?.phone?.message?.length ? "d-block p-3" : "d-none"
          }`}
        >
          {errors.phone?.message}
        </p>
        <FormInput
          type="text"
          label="Whatsapp number"
          {...register("phone", {
            required: true,
            pattern: {
              value: /(^0[789]\d{9}$)/,
              message: "Please enter a valid phone number",
            },
          })}
        />

        <FormInput
          type="email"
          label="Email address"
          {...register("email", { required: true })}
        />
        <div className="d-flex">
          <button
            type="submit"
            className="font-17 d-flex call-number btn btn-blue"
            disabled={(isCheckRideUserLoading || isCheckRideLoanRequestLoading)}
          >
            {(isCheckRideUserLoading || isCheckRideLoanRequestLoading) ? "Please wait" : `${data?.plan.plan_amount === "3000" ? 'Continue' : "Check eligibility"}`}
            {(isCheckRideUserLoading || isCheckRideLoanRequestLoading) ? (
              <span className="ml-3 spin">
                <i className="fa-solid fa-spinner"></i>
              </span>
            ) : (
              <></>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default UserDetails;
