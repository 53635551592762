import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
import useGlobalStore from "../../store/global";

function Hero() {
  const toggleIsSignupOpen = useGlobalStore(
    (state) => state.toggleIsSignupOpen
  );

  const el = useRef(null);
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["about your wellbeing", "about your safety", "about your security"],
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 100,
    });
    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  let today = new Date();
  return (
    <>
      <div
        style={{ backgroundColor: "#F3EFE6" }}
        className="dotted-bg overflow-hidden hero position-relative"
      >
        <header>
          <nav className="navigation position-absolute d-flex align-items-center">
            <div className="container">
              <div className="d-flex align-items-center justify-content-between navigation__inner">
                <div className="navigation__logo-container">
                  {today.getMonth() === 11 &&
                  (today.getDate() > 20 || today.getDate() < 31) ? (
                    <>
                      <img
                        src="/assets/images/clan-christmas-dark.png"
                        className="navigation__logo"
                        alt="logo"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src="/assets/images/clan.png"
                        className="navigation__logo"
                        alt="logo"
                      />
                    </>
                  )}
                </div>
                <div className="navigation__links d-none d-md-block">
                  <a
                    href="https://ride.clan.africa/"
                    className="navigation__link text-black font-weight-bold"
                  >
                    Pay later
                  </a>
                  <a
                    href="https://ride.clan.africa/discount"
                    className="navigation__link text-black font-weight-bold"
                  >
                    Ride discounts
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <div className="container">
          <div className="pt-5 row justify-content-between">
            <div className="pt-5 col-lg-6 col-md-6 mb-5">
              <div className="hero__title pb-4">
                <div className="text">
                  <h1 className="text-cc-dark">
                    <span className="text-cc-dark">
                    We care
                    </span>{" "}
                    <br />
                    <span className="type text-cc" ref={el}></span>{" "}
                    <br /> 
                  </h1>
                </div>
              </div>

              <p data-aos="fade-right" className="lead">
              Due to the most recent security constraints, we have provided a free emergency bolt ride for eligible individuals between the hours of 8:30 pm and  5:30 am.
              </p>

              <button
                onClick={toggleIsSignupOpen}
                className="btn cta mt-4 shadow btn-primary font-18"
              >
                Get free ride
              </button>
              <p data-aos="fade-right" className="lead pt-3 opacity-2">
              Terms and conditions apply *
              </p>
            </div>
            <div className="col-md-6 position-relative ">
              <img
                style={{ width: "85%" }}
                className="home-img"
                src="assets/images/care.gif"
                alt=""
              />
              <img
                src="assets/images/svg/dot.svg"
                className="position-absolute hero__dots"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
