import React, { useState } from "react";
import FormInput from "../home/FormInput.jsx";
import useSignupStore from "../../store/signup.js";

const BoltPhone = ({ onNext, onBack }) => {
  const { data, updateData } = useSignupStore((state) => state);
  const [boltPhone, setBoltPhone] = useState(data?.bolt_phone || "");

  const handleSubmit = () => {
    updateData({ bolt_phone: boltPhone });
    onNext();
  };
  const handleChange = (event) => {
    setBoltPhone(event.target.value);
  };

  return (
    <>
      <div>
        <button
          style={{ marginBottom: "0px" }}
          className="back"
          type="button"
          onClick={onBack}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-angle-left"
            ></i>
          </span>
        </button>
      </div>
      <div className="pt-70 pb-5">
        <p className="redirect-text font-30 text-deep-blue font-weight-bold mb-1">
          Kindly provide your registered bolt phone number
        </p>
      </div>
      <FormInput
        type="text"
        label="Bolt phone number"
        value={boltPhone}
        onChange={handleChange}
      />

      <div className="d-flex">
        <button
          onClick={handleSubmit}
          type="submit"
          className="font-17 d-flex call-number btn btn-blue"
          disabled={!boltPhone}
        >
          Continue
        </button>
      </div>
    </>
  );
};

export default BoltPhone;
