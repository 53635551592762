import React from "react";

function Family() {
  return (
    <>
      <div className="family">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sec-title">
                <h2 className="title-small">
                  This is just to let you know <br/> We're now Clan, but you can call
                  us family.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="about-animation">
          <div class="animate one">
            <img src="/assets/images/dot1.png" alt="About" />
          </div>
          <div class="animate two">
            <img src="/assets/images/dot2.png" alt="About" />
          </div>
          <div class="animate three">
            <img src="/assets/images/dot3.png" alt="About" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Family;
