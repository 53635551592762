import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
import useGlobalStore from "../../store/global";

function Hero() {
  const toggleIsSignupOpen = useGlobalStore(
    (state) => state.toggleIsSignupOpen
  );

  const el = useRef(null);
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["Uber now", "Bolt now", "InDrive now", "Ride now"],
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 100,
    });
    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  let today = new Date();
  return (
    <>
      <div
        style={{ backgroundColor: "#F3EFE6" }}
        className="dotted-bg overflow-hidden hero position-relative"
      >
        <header>
          <nav className="navigation position-absolute d-flex align-items-center">
            <div className="container">
              <div className="d-flex align-items-center justify-content-between navigation__inner">
                <div className="navigation__logo-container">
                  {today.getMonth() === 11 &&
                  (today.getDate() > 20 || today.getDate() < 31) ? (
                    <>
                      <img
                        src="/assets/images/clan-christmas-dark.png"
                        className="navigation__logo"
                        alt="logo"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src="/assets/images/clan.png"
                        className="navigation__logo"
                        alt="logo"
                      />
                    </>
                  )}
                </div>

                <div className="navigation__links d-none d-md-block">
                  <a
                    href="/"
                    className="navigation__link text-black font-weight-bold"
                  >
                    Home
                  </a>
                  <a
                    href="#howItWorks"
                    className="navigation__link text-black font-weight-bold"
                  >
                    How it works
                  </a>
                  <a
                    href="/discount"
                    className="navigation__link text-black font-weight-bold"
                  >
                    Ride discount
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <div className="container">
          <div className="pt-5 row justify-content-between">
            <div className="pt-4 col-lg-5 col-md-6 mb-5">
              <div className="hero__title pb-4">
                <div className="text">
                  <h1 className="text-cc-dark">
                    <span className="type text-cc" ref={el}></span> <br/>
                    <span className="text-cc-dark">Pay monthly</span> <br />{" "}
                  </h1>
                </div>
              </div>

              <p data-aos="fade-right" className="lead">
              We are making mobility as easy as ABC. Subscribe for ride plans and get unbelievable bonuses a you ride.
              </p>

              <button
                onClick={toggleIsSignupOpen}
                className="btn cta mt-4 shadow btn-primary font-18"
              >
                Set me up
              </button>
            </div>
            <div className="col-md-6 position-relative ">
              <img
                style={{ width: "120%" }}
                className="home-img"
                src="assets/images/car.png"
                alt=""
              />
              <img
                src="assets/images/svg/dot.svg"
                className="position-absolute hero__dots"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
