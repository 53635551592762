import React from "react";
import useGlobalStore from '../../store/global';


function NoPlan({ onBack, onNext }) {
  const toggleIsSignupOpen = useGlobalStore(state => state.toggleIsSignupOpen);

  return (
    <>
     <div>
        <button
          style={{ marginBottom: "0px" }}
          className="back"
          type="button"
          onClick={onBack}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-angle-left"
            ></i>
          </span>
        </button>
      </div>
      <div className="pt-70">
        <img
          style={{ height: "250px", width: "auto", marginLeft: "-3rem" }}
          src="/assets/images/Front car-pana.svg"
          alt=""
        />
        <p className="mb-1 redirect-text text-cc-dark font-weight-bold font-22 text-left mb-2 mt-4">
          You are not subscribed to any ride plan. Do you wish to choose a plan ?
        </p>
        <div className="d-flex align-items-center">
        <button
            onClick={toggleIsSignupOpen} className="d-flex align-items-center px-5 btn btn-outline-gray mr-4"
          >
           No, later
          </button>
          <button onClick={() => onNext() } className="px-5 btn btn-blue">
            Yes
          </button>
        </div>
      </div>
    </>
  );
}

export default NoPlan;
