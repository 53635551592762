import React from 'react'
import { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import useSignupStore from '../store/signup';

export const Layout = ({children}) => {
    const { updateData } = useSignupStore((state) => state);

    let [searchParams] = useSearchParams();

    useEffect(() => {
       const from = searchParams.get("f")
       updateData({from})
    }, [searchParams, updateData])
  return (
    <div>{children}</div>
  )
}
