import React, { useEffect, useState } from "react";
import Success from "../outright-ride-signup/Success";
import RidePlans from "../outright-ride-signup/RidePlans";
import UserDetails from "../outright-ride-signup/UserDetails";
import PendingRequest from "../outright-ride-signup/PendingReuest";
import PayViaTransferModal from "../sign-up/outright/PayViaTransferModal";
import useSignupStore from "../../store/signup";
import ActivePlan from "../sign-up/ActivePlan";
import CancelRideRequest from "../sign-up/CancelRideRequest";
import PlanDetails from "../outright-ride-signup/PlanDetails";
import BoltPhone from "../outright-ride-signup/BoltPhone";
import WhatsappPrompt from "../sign-up/registered/WhatsappPrompt";

function WhatsappSlideout({ isActive, handleToggle }) {
  const { data } = useSignupStore((state) => state);

  const [view, setView] = useState("ride-plans");

  useEffect(() => {
    if (isActive) document.scrollingElement.style.overflow = "hidden";
    else document.scrollingElement.style.overflowY = "auto";
  }, [isActive]);

  return (
    <>
      <div
        onClick={handleToggle}
        className={`main-overlay ${isActive === true ? "visible" : ""}`}
      ></div>
      <div className={`dotted-bg sidebar ${isActive ? "active" : ""}`}>
        {view === "ride-plans" && (
          <RidePlans
            handleToggle={handleToggle}
            onNext={(v) => setView(v)}
          />
        )}
        {
          view === "plan-details" && (
            <PlanDetails onBack={() => setView("ride-plans")} onNext={() => setView("bolt-phone")} />
          )
        }
        {
          view === "bolt-phone" && (
            <BoltPhone onBack={() => setView("ride-plans")} onNext={() => setView("user-details")}/>
          )
        }
        {view === "user-details" && (
          <UserDetails
            onBack={() => setView("bolt-phone")}
            onNext={(v) => setView(v)}
          />
        )}
        {view === "pending-request" && (
          <PendingRequest
            onBack={() => setView("user-details")}
            onNext={(v) => setView(v)}
          />
        )}
        {view === "cancel-ride-request" && (
          <CancelRideRequest
            onBack={() => setView("pending-request")}
            onNext={() => setView("pay-outright")}
          />
        )}
        {view === "active-plan" && (
          <ActivePlan
            onBack={() => setView("user-details")}
            onDone={() => setView("registered")}
          />
        )}
        {view === "pay-outright" && (
          <PayViaTransferModal
            amount={
              data.ride_data?.amount -
              (data.ride_data?.discount / 100) * data.ride_data?.amount
            }
            discount={data.ride_data?.discount}
            oldAmount ={data.ride_data?.amount}
            phone={data.user?.phone}
            full_name={data.user?.name}
            onClose={() => setView("user-details")}
            onDone={() => setView("success")}
          />
        )}
        {view === "success" && <Success onNext={() => setView("whatsapp-prompt")} />}
        {
          view === "whatsapp-prompt" && <WhatsappPrompt onBack={() => setView("success")} />
        }
      </div>
    </>
  );
}

export default WhatsappSlideout;
