import React from 'react'
import { useState } from 'react'
import PayViaTransferModal from './PayViaTransferModal'
import RideService from './RideService'
import useSignupStore from '../../../store/signup'
import UserDetails from './UserDetails'
import Success from './Success'
import CancelRideRequest from '../CancelRideRequest'
import ActivePlan from '../ActivePlan'
import PendingRequest from './PendingRequest'
import WhatsappPrompt from '../registered/WhatsappPrompt'

function Outright({onBack, onNext, onDone, handleToggle}) {
const { data } = useSignupStore((state) => state);

const [ view, setView ] = useState("ride-service")

  return (
   
    <>
    {
        view === "ride-service" && 
        <RideService onBack={onBack} onNext={() => setView("user-details")}/>
    }
    {
        view === "user-details" &&
        <UserDetails onBack={() => setView("ride-service")} onNext={(v) => setView(v)} />
    }
    {
      view === "pending-request" && (
        <PendingRequest onBack={() => setView("user-details")} onNext={(v) => setView(v)} />
      )
    }
    {
      view === "active-plan" && (
        <ActivePlan onBack={() => setView("user-details")} onDone={onDone} />
      )
    }
    
    {
      view === "cancel-ride-request" && (
        <CancelRideRequest  onBack={() => setView("pending-request")} onNext={() => setView("pay-outright")}/>
      )
    }
    {
        view === "pay-outright" && 
        <PayViaTransferModal oldAmount ={data.ride_data?.amount} discount={data.ride_data?.discount} amount={data.ride_data?.amount - ((data.ride_data?.discount / 100) * data.ride_data?.amount)} phone={data.user?.phone} full_name={data.user?.name} onClose={() => setView("user-details")} onDone={() => setView("success")}/>
    }
    {
      view === "success" &&
      <Success onNext={() => setView("whatsapp-prompt")}/>
    }
    {
      view === "whatsapp-prompt" && <WhatsappPrompt onBack={() => setView("success")} />
    }
    </>
  )
}

export default Outright