import React from 'react'

function PlanExpired({ onBack }) {
    return (

        <>
            <div>
                <button style={{ marginBottom: "0px" }} className="back" type="button" onClick={onBack}>
                    <span aria-hidden="true"><i style={{ fontSize: "1rem !important" }} className="fa-solid fa-angle-left"></i></span>
                </button>
            </div>
            <div className="pt-70 pb-5">
                <p className="font-30 redirect-text text-deep-blue font-weight-bold">
                    <span><i className="fa-solid fa-triangle-exclamation mr-2"></i></span>
                    Oops, your current plan has expired
                </p>
                <p className="font-17 redirect-text text-cc-dark mt-3">
                    Please pay back your loan in order to be eligible to pay for a ride
                </p>
                <a href="https://wa.me/09053535333?text=repay" target="_blank" rel="noreferrer" className="mt-3 call-number btn btn-blue font-17">Repay Now</a>
            </div>
            <div className='illustration-image'>
                <img style={{ width: "100%" }} src="/assets/images/Wallet-bro.svg" alt="" />
            </div>
        </>
    )
}

export default PlanExpired