import { data } from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { numberWithCommas } from "../../../lib/utils";

const AccountDetails = ({
  account,
  amount,
  onConfirm,
  onGenerate,
  onCancel,
  oldAmount,
  discount,
  onSkip
}) => {
  const [timer, setTimer] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [expired, setExpired] = useState(false);
  const interval = useRef(null);

  useEffect(() => {
    startTimer();
    return () => clearTimer();
  }, []);

  const countdown = () => {
    setExpired(false);
    const future = new Date(account.expiry_date);
    const now = new Date(Date.now());
    if (future < now) {
      setTimer({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      setExpired(true);
      return clearTimer();
    }
    const diff = future - now;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    setTimer({ days, hours, minutes, seconds });
  };

  const startTimer = () => {
    countdown();
    interval.current = setInterval(() => countdown(), 1000);
  };

  const clearTimer = () => {
    if (interval.current) clearInterval(interval.current);
  };

  return (
    <>
      <div className="pt-70 pb-5">
        <p className="redirect-text font-30 text-cc-dark font-weight-bold mb-1">
          Make payment{" "}
          <span className={amount < 500 ? "d-none" : ""}>
            ({discount}% discount)
          </span>
        </p>
        <p className="text-cc-dark font-18 mb-5">
          Kindly transfer exactly{" "}
          <span className={`old-amount ${amount < 500 ? "d-none" : ""}`}>
            ₦{numberWithCommas(oldAmount)}
          </span>{" "}
          <span>₦{numberWithCommas(amount)}</span> into the account below
        </p>
        <hr />
        <div>
          <div className="d-flex align-center justify-content-space-between">
            <p className="text-cc-dark font-18">
              Account name: {account.merchant_name}
            </p>
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-space-between">
            <p className="text-cc-dark font-18">
              Bank name: {account.bank_name}
            </p>
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-space-between">
            <p className="text-cc-dark font-18">
              Account number: {account.account_number}
            </p>
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-space-between mb-5">
            {!expired ? (
              <>
                <p className="text-cc-dark font-18 mr-3">Expires in </p>
                <p className="text-cc-dark font-18">
                  {timer.hours} : {timer.minutes} : {timer.seconds}
                </p>
              </>
            ) : (
              <p className="text-danger font-18">
                <span>
                  <i className="fa-solid fa-circle-exclamation pr-2"></i>
                </span>
                Expired
              </p>
            )}
          </div>
        </div>

        {!expired ? (
          <div className="d-flex">
            <button
              onClick={onCancel}
              className="font-17 d-flex call-number btn btn-outline-gray mr-3"
            >
              Cancel
            </button>
            <button
                onClick={onConfirm}
                className="font-17 d-flex call-number btn btn-blue-full"
              >
                Confirm transfer
              </button>
          </div>
        ) : (
          <button className="font-17 d-flex call-number btn btn-blue-full" onClick={onGenerate}>Generate new account</button>
        )}
      </div>
    </>
  );
};

export default AccountDetails;
