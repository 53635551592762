import React from "react";

function Success({handleToggle}) {

  return (
    <>
      <div className="position-relative">
        <button
          style={{ marginBottom: "0px" }}
          className="x sidebarCollapse"
          type="button"
          onClick={handleToggle}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-x"
            ></i>
          </span>
        </button>
      </div>
      <div className="pt-70">
      <img style={{ width: "300px" }} src="/assets/images/Enthusiastic-cuate.svg" alt="" />
        <p className="redirect-text font-30 text-deep-blue font-weight-bold mb-3 mt-4">
          Congratulations,
        </p>
        <p className="font-17 text-cc-dark mb-3">
          You have been successfully set up. We will get back to you shortly. You can also reach us on +2349155577731
        </p>
          <a href="tel:+2349155577731" className="btn btn-blue-full font-17">
            <span className="mr-3"><i className="fa-solid fa-phone"></i></span>
            Call +2349155577731
          </a>
      </div>
    </>
  );
}

export default Success;
