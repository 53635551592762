import React from "react";
import useSignupStore from "../../store/signup";
import { numberWithCommas } from "../../lib/utils";

function PlanDetails({ onBack, onNext }) {
  const { data } = useSignupStore((state) => state);

  return (
    <>
      <div>
        <button
          style={{ marginBottom: "0px" }}
          className="back"
          type="button"
          onClick={onBack}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-angle-left"
            ></i>
          </span>
        </button>
      </div>
      <div className="pt-70 pb-3">
        <p className="redirect-text font-30 text-deep-blue font-weight-bold mb-2">
          {data.plan.package}
        </p>
        <p className="text-cc-dark font-20 mb-4">
          {" "}
          ₦{numberWithCommas(data.plan.plan_amount)}
        </p>
        <div>
          <hr />
          <li className="text-cc-dark font-18 mb-2">
            100 customers benefit monthly
          </li>
          <li className="text-cc-dark font-18 mb-2">
            Use bonus during absolute emergencies
          </li>
          <li className="text-cc-dark font-18 mb-2">
            A Bolt account is needed to get started
          </li>
          <li className="text-cc-dark font-18 mb-2">
            You also need to have a LinkedIn account
          </li>
          <li className="text-cc-dark font-18 mb-2">
            Effective during the hours of 8:30pm and 5:30am
          </li>
          <li className="text-cc-dark font-18 mb-2">
            Applications are subject to T&Cs
          </li>

          <button
            onClick={onNext}
            className="mt-4 font-17 d-flex call-number btn btn-blue"
          >
            Choose Plan
          </button>
        </div>
        <div
          style={{ width: "70%", right: "-1rem" }}
          className="illustration-image"
        >
          <img src="/assets/images/car-ride.png" alt="" />
        </div>
      </div>
    </>
  );
}

export default PlanDetails;
