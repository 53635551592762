import React from 'react'

function Loader({ header, subText }) {
    return (
        <>
            <div className="pt-70 pb-3">
                <p className="redirect-text font-30 text-cc-dark font-weight-bold mb-1">
                    {header}
                </p>
                <p className='text-cc-dark font-18'>{subText}</p>
            </div>
            <div className='fetch-restaurant-gif'>
                <img src="/assets/images/ride.gif" alt="" />
            </div>
        </>
    )
}

export default Loader