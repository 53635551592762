import React from "react";
import ReactDOM from "react-dom/client";
import "./global-styles.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import Home from "./views/Home";
import OutrightRide from "./views/OutrightRide";
import FreeRide from "./views/FreeRide";
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/discount",
    element: <OutrightRide/>
  },
  {
    path: "/freeride",
    element: <FreeRide/>,
  },
  {
    path: "/freeride/linkedin",
    element: <LinkedInCallback/>,
  }
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
</QueryClientProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
