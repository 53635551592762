import React from "react";

function PendingRequest({handleToggle}) {

  return (
    <>
      <div className="position-relative">
        <button
          style={{ marginBottom: "0px" }}
          className="x sidebarCollapse"
          type="button"
          onClick={handleToggle}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-x"
            ></i>
          </span>
        </button>
      </div>
      <div className="pt-70">
      <img
          className="success-bolt-img"
          src="/assets/images/bolt-img-1.jpg"
          alt=""
        />
        <img
          className="success-bolt-img"
          src="/assets/images/bolt-img-2.jpg"
          alt=""
        />
        <p className="redirect-text font-30 text-deep-blue font-weight-bold mb-3 mt-4">
          You have already signed up to the free ride plan
        </p>
          <a href="tel:+2349155577731" className="btn btn-blue-full font-17">
            <span className="mr-3"><i className="fa-solid fa-phone"></i></span>
            Call +2349155577731
          </a>
      </div>
    </>
  );
}

export default PendingRequest;
