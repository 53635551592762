import React from "react";
import useGlobalStore from "../../store/global";

function CarGif() {
  const toggleIsSignupOpen = useGlobalStore(
    (state) => state.toggleIsSignupOpen
  );

  return (
    <>
      <section
        style={{ backgroundColor: "#f8f7ff" }}
        className="overflow-hidden bg-house position-relative"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-4">
              <img
                className="mobile-phone"
                data-aos="fade-down"
                src="/assets/images/carGif.gif"
                width="90%"
                alt=""
              />
            </div>
            <div className="col-md-5">
              <h1 data-aos="fade-left" className="title-small">
                A special gift from us to you
              </h1>
              <p data-aos="fade-right" className="lead">
                To ensure that individuals get home to their loved ones safely,
                We have come up with this care package for all the strong and
                hardworking professionals out there
              </p>
              <button
                onClick={toggleIsSignupOpen}
                className="btn mt-4 shadow btn-primary cta"
              >
                Claim gift
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CarGif;
