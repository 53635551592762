import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import useSignupStore from '../../store/signup';
import { useUpdateRideUserMutation } from '../../api/ride'
import Loader from './Loader';

function RenewPlan({ onBack, onNext }) {
    const { data, updateData } = useSignupStore(state => state);

    const { mutateAsync: updateRideUser, isLoading: isUpdateRideUserLoading } = useUpdateRideUserMutation()

    const submit = async () => {
        try {
            const payload = {
                phone: data.user.phone,
                plan_amount: data.plan.plan_amount,
                creditclan_request_id: ""
            }
            const res = await updateRideUser(payload);
            let expiry_date = new Date(res.data.data.expiry_date);
            let expired_date = expiry_date.toDateString();

            updateData({
                user: {
                    ...data.user,
                    name: res.data.data.name,
                },
                plan_expiry_date: expired_date
            })
            onNext("success");
        } catch (e) {
            console.log({ e });
        }
    }
    return (
        <>
            <div>
                <button style={{ marginBottom: "0px" }} className="back" type="button" onClick={onBack}>
                    <span aria-hidden="true"><i style={{ fontSize: "1rem !important" }} className="fa-solid fa-angle-left"></i></span>
                </button>
            </div>
            {
                !isUpdateRideUserLoading &&
                <>
                    <div className="pt-70">
                        <p className="font-30 redirect-text text-deep-blue font-weight-bold">
                            <span><i className="fa-solid fa-triangle-exclamation mr-2"></i></span>
                            Oops, your current plan has expired
                        </p>
                        <p className="font-17 redirect-text text-cc-dark mt-3">
                            Do you wish to renew it or choose another plan ?
                        </p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <Swiper
                                slidesPerView={1.3}
                                spaceBetween={40}
                                breakpoints={{
                                    470: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 20
                                    },
                                    760: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 20
                                    },
                                    1100: {
                                        slidesPerView: 1.3,
                                        spaceBetween: 20
                                    }
                                }}
                                className="showcase">
                                <div className="swiper-pagination"></div>
                                <div className="swiper-container">
                                    <div className="swiper-wrapper">
                                        <SwiperSlide>
                                            <div
                                                onClick={submit}
                                                data-wow-delay="0.1s"
                                                className="feature-box summary-card mb-4 feature-box-rounded"
                                            >
                                                <div className='pb-2'>
                                                    <img src="/assets/images/autorenew_FILL0_wght400_GRAD0_opsz48.svg" alt="" />
                                                </div>
                                                <div>
                                                    <p className='font-30 mb-0'>
                                                        Renew Plan
                                                    </p>
                                                    <p className='font-17 text-white pt-3'>
                                                        Continue with the previous plan and start eating
                                                    </p>
                                                </div>
                                                <div className="icon"></div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div
                                                onClick={() => onNext("ride-plans")}
                                                data-wow-delay="0.1s"
                                                className="feature-box summary-card mb-4 feature-box-rounded"
                                            >
                                                <div className='pb-2'>
                                                    <img src="/assets/images/directions_car_FILL0_wght400_GRAD0_opsz48.svg" alt="" />
                                                </div>
                                                <div>
                                                    <h3 className='font-30 mb-0'>
                                                        Change Plan
                                                    </h3>
                                                    <p className='font-17 text-white pt-3'>
                                                        Choose a new plan and start eating immediately
                                                    </p>
                                                </div>
                                                <div className="icon"></div>
                                            </div>
                                        </SwiperSlide>
                                    </div>
                                </div>
                            </Swiper>
                        </div>
                    </div>
                </>
            }
            {
                isUpdateRideUserLoading &&
                <Loader
                    header={"Renewing your plan "}
                    subText={"Please wait while we renew your ride plan"}
                />
            }
        </>
    )
}

export default RenewPlan