import React from 'react'
import useGlobalStore from '../../store/global'

function Footer() {
    const toggleIsSignupOpen = useGlobalStore(state => state.toggleIsSignupOpen);

    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="footer__logo"></div>
                        <div className="footer__links">
                            <a href="/" className="text-black font-weight-bold footer__link font-18">Home</a>
                            <button onClick={toggleIsSignupOpen} className="footer__link btn cta btn-primary font-18">Ride
                                now</button>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer