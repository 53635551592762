import React from "react";
import Benefits from "../component/home/Benefits";
import Craving from "../component/home/Craving";
import Footer from "../component/home/Footer";
import Hero from "../component/home/Hero";
import HowItWorks from "../component/home/HowItWorks";
import WhyUs from "../component/home/WhyUs";
import WhatsappSlideout from "../component/home/WhatsappSlideout";
import useGlobalStore from "../store/global";
import { Layout } from "../component/Layout";

function Home() {
  const { isSignupOpen, toggleIsSignupOpen } = useGlobalStore((state) => ({
    isSignupOpen: state.data.isSignupOpen,
    toggleIsSignupOpen: state.toggleIsSignupOpen,
  }));

  return (
    <Layout>
      <div className="wrapper">
        <div className="main">
          <Hero />
          <WhyUs />
          <HowItWorks />
          <Benefits />
          <Craving />
          <Footer />
        </div>
        <WhatsappSlideout
          isActive={isSignupOpen}
          handleToggle={() => toggleIsSignupOpen()}
        />
      </div>
    </Layout>
  );
}

export default Home;
