import React, { useEffect, useRef, useState } from 'react';
import { useVerifyTransactionMutation } from '../../../api/ride.js';
import useSignupStore from '../../../store/signup.js';

const ConfirmTransfer = ({ transaction_reference, onDone, onCancel }) => {
  const { updateData } = useSignupStore((state) => state);

  let interval = useRef(null);
  const retries = useRef(10);
  const { mutateAsync: verifyTransaction } = useVerifyTransactionMutation();
  
  const [error, setError] = useState(false)

  useEffect(() => {
    interval.current = setInterval(async () => {
      await confirm();
      if (retries.current > 0) retries.current -= 1;
      if (retries.current === 0) {
        clearInterval(interval.current);
        return onCancel();
      }
    }, 5000);
    return () => {
      if (interval.current) clearInterval(interval.current);
    }
  }, []);

  const confirm = async () => {
    setError(false)
    try {
      const data = await verifyTransaction({ transaction_reference });
      updateData({payment: {...data.payment, transaction_reference}})
      if (!data.status) {
        if (retries.current === 1) {
          setError(true)
        }
      } else {
        retries.current = 0;
        onDone();
      }
    } catch (e) {
      setError(true)
    }
  };

  return (
    <div className='d-flex flex-column justify-content-center align-items-center' py="120px">
       <>
          <br />
          <br />
          <br />
          <br />
          <div className="d-flex flex-column justify-content-center align-items-center text-center py-5">
            <div className="spinner-grow text-dark" role="status">
              <span className="sr-only text-cc-dark">Loading...</span>
            </div>
            <div className="font-17 mt-4 text-cc-dark">Confirming your transfer..</div>
          </div>
        </>
      <button  className="font-17 d-flex call-number btn btn-outline-gray" onClick={ onCancel } variant="default" radius="xl" mt="30px">Cancel</button>
    </div>
  );
};

export default ConfirmTransfer;
