import React, { useState } from "react";
import FormInput from "../home/FormInput.jsx";
import { useForm } from "react-hook-form";
import useSignupStore from "../../store/signup.js";
import {
  useGetPlanBalanceMutation,
  useCheckRideUserMutation,
  useCheckRideLoanRequestMutation
} from "../../api/ride.js";

const Phone = ({ onNext, onBack }) => {
  const { data, updateData } = useSignupStore((state) => state);
  const [error, setError] = useState("false");

  const { mutateAsync: getPlanBalance, isLoading: isGetPlanBalanceLoading } =
    useGetPlanBalanceMutation();
  const { mutateAsync: checkRideUser, isLoading: isCheckRideUserLoading } =
    useCheckRideUserMutation();
    const {
      mutateAsync: checkRideLoanRequest,
      isLoading: isCheckRideLoanRequestLoading,
    } = useCheckRideLoanRequestMutation();
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...data?.user },
  });

  const submit = async ({ phone }) => {
    setError("false");
    try {
      const payload = {
        phone: phone,
      };
      const res = await checkRideUser(payload);
      if (!!res?.data?.data) {
        if (!res?.data?.data?.plan_amount) {
          updateData({
            user: {
              phone: phone,
              name: res.data.data.name,
            },
          });
          return onNext("no-plan");
        }
        let expiry_date = new Date(res.data.data.plan_expiry_date);
        let today_date = new Date();
        if (today_date > expiry_date) {
          const res_two = await getPlanBalance(phone);
          if (!res_two.data.status) {
            updateData({
              user: {
                phone: phone,
              },
              plan_amount: res.data.data.plan_amount,
            });
            return onNext("renew-plan");
          }
          return onNext("plan-expired");
        }
        updateData({
          user: {
            phone: phone,
            name: res.data.data.name,
            email: res.data.data.email,
          },
          plan_amount: res.data.data.plan_amount,
          plan_expiry_date: res.data.data.plan_expiry_date,
        });
        const payload_two = {
          rider_id: res?.data?.data?.id,
          amount:  res?.data?.data?.plan_amount,
        };
        const res_two = await checkRideLoanRequest(payload_two);
        updateData({ ride_data: res_two.data });
        onNext("active-plan");
      } else {
        updateData({ user: { phone: phone } });
        setError("true");
      }
    } catch (e) {
      console.log({ e });
    }
  };

  return (
    <>
      <div>
        <button
          style={{ marginBottom: "0px" }}
          className="back"
          type="button"
          onClick={onBack}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-angle-left"
            ></i>
          </span>
        </button>
      </div>
      <div className="pt-70 pb-5">
        <p className="font-30 redirect-text text-deep-blue font-weight-bold">
          Please provide your whatsapp phone number
        </p>
        <p
          className={`redirect-text text-white bg-danger error-text font-17 pb-0 ${
            error === "true" ? "d-block p-3" : "d-none"
          }`}
        >
          User not found. Kindly select a plan from the menu
        </p>
      </div>

      <form onSubmit={handleSubmit(submit)}>
        <p
          className={`redirect-text text-white bg-danger error-text font-17 pb-0 ${
            errors?.phone?.message?.length ? "d-block p-3" : "d-none"
          }`}
        >
          {errors.phone?.message}
        </p>
        <FormInput
          type="tel"
          label="Phone number"
          {...register("phone", {
            required: true,
            pattern: {
              value: /(^0[789]\d{9}$)/,
              message: "Please enter a valid phone number",
            },
          })}
        />
        <button
          type="submit"
          className="font-17 d-flex call-number btn btn-blue"
          disabled={isCheckRideUserLoading || isGetPlanBalanceLoading || isCheckRideLoanRequestLoading}
        >
          {isCheckRideUserLoading || isGetPlanBalanceLoading || isCheckRideLoanRequestLoading
            ? "Please wait"
            : "Proceed"}
          {isCheckRideUserLoading || isGetPlanBalanceLoading || isCheckRideLoanRequestLoading ? (
            <span className="ml-3 spin">
              <i className="fa-solid fa-spinner"></i>
            </span>
          ) : (
            <></>
          )}
        </button>
        <div style={{ right: "-6rem" }} className="illustration-image">
          <img src="/assets/images/phone-in-hand.png" alt="" />
        </div>
      </form>
    </>
  );
};

export default Phone;
