import React, { useState, useEffect } from "react";
import useSignupStore from "../../../store/signup.js";
import {
  useUpdateRideUserMutation,
  useUpdateActiveRideUserMutation,
} from "../../../api/ride.js";
import Loader from "../Loader.jsx";
import useEligibility from "../../hooks/use-eligibility.js";

function LaunchPay({ onBack, onNext }) {
  const { data, updateData } = useSignupStore((state) => state);

  const [loading, setLoading] = useState("");

  const { mutateAsync: updateRideUser, isLoading: isUpdateRideUserLoading } =
    useUpdateRideUserMutation();
  const {
    mutateAsync: updateActiveRideUser,
    isLoading: isUpdateActiveRideUserLoading,
  } = useUpdateActiveRideUserMutation();

  const { launch: launchPay } = useEligibility({
    data: {
      intro: "Add your card to start riding now",
      intro_action: 'Continue',
      banner: "https://i.ibb.co/F3HsSx0/eligibility-banner.jpg",
      extra: {
        ride_id: data?.ride_data.id
      }, 
      request: {
        amount: 3000,
        tenor: 1,
        tenor_type: 2,
        product_id: "30003",
      },
      profile: {
        full_name: data.user.name,
        email: data.user.email,
        phone: data.user.phone,
        date_of_birth: null,
        gender: null,
      },
      config: {
        platform: "ride",
        tokenize_card: true,
        show_basic_details: true
      },
    },
    onReady: () => {
      setLoading("false");
    },
    onCompleted: (data) => {
      handleEligibilityCompleted({plan_amount: 3000, request_id: data.request_id});
    },
    onCancel: () => {
      onBack()
    }
  });

  const handlePay = () => {
    setLoading("true");
    launchPay();
  };
  const handleEligibilityCompleted = async ({plan_amount, request_id}) => {
    try {
      const payload = {
        phone: data.user.phone,
        plan_amount: plan_amount,
        creditclan_request_id: request_id
      };
      const res = await updateRideUser(payload);
      let expiry_date = new Date(res.data.data.plan_expiry_date);
      let expired_date = expiry_date.toDateString();

      updateData({
        user: {
          ...data.user,
          name: res.data.data.name,
        },
        plan_amount: res.data.data.plan_amount,
        plan_expiry_date: expired_date,
      });

      const payload_two = {
        status: "active",
        id: res.data.data.id,
      };
      await updateActiveRideUser(payload_two);
      onNext();
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
      handlePay()
  }, [])
  
  return (
    <>
      {loading === "true" &&
        !isUpdateRideUserLoading &&
        !isUpdateActiveRideUserLoading && (
          <>
            <br />
            <br />
            <br />
            <br />
            <div className="d-flex flex-column justify-content-center align-items-center text-center py-5">
              <div className="spinner-grow text-dark" role="status">
                <span className="sr-only text-cc-dark">Loading...</span>
              </div>
              <div className="font-17 mt-4 text-cc-dark">Please wait..</div>
            </div>
          </>
        )}
      {!loading && isUpdateRideUserLoading && isUpdateActiveRideUserLoading && (
        <Loader
          header={"Creating your account"}
          subText={"Please wait while we set you up"}
        />
      )}
    </>
  );
}

export default LaunchPay;
