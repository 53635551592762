import React from "react";
import WhatsappSlideout from "../component/outright-ride/WhatsappSlideout";
import { Layout } from "../component/Layout";
import useGlobalStore from "../store/global";
import Hero from "../component/free-ride/Hero"
import FreeRideSlideout from "../component/free-ride/FreeRideSlideout";
import CarGif from "../component/free-ride/CarGif";
import Footer from "../component/home/Footer";
import Family from "../component/free-ride/Family";

function FreeRide() {
  const { isSignupOpen, toggleIsSignupOpen } = useGlobalStore((state) => ({
    isSignupOpen: state.data.isSignupOpen,
    toggleIsSignupOpen: state.toggleIsSignupOpen,
  }));

  return (
    <Layout>
      <div className="wrapper">
        <div className="main">
        <Hero/>
        <CarGif/>
        <Family/>
        <Footer/>
        </div>
        <FreeRideSlideout
          isActive={isSignupOpen}
          handleToggle={() => toggleIsSignupOpen()}
        />
      </div>
    </Layout>
  );
}

export default FreeRide;
