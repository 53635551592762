import React from "react";
import useGlobalStore from "../../../store/global";

function Success({ onNext }) {
  const toggleIsSignupOpen = useGlobalStore(
    (state) => state.toggleIsSignupOpen
  );

  return (
    <>
     <div className="position-relative">
     <button
          style={{ marginBottom: "0px" }}
          className="x sidebarCollapse"
          type="button"
          onClick={toggleIsSignupOpen}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-x"
            ></i>
          </span>
        </button>
     </div>
      <div className="pt-50">
        <img
          style={{ width: "300px" }}
          src="/assets/images/Enthusiastic-cuate.svg"
          alt=""
        />
        <p className="redirect-text font-30 text-deep-blue font-weight-bold mb-0">
          Congratulations,
        </p>
        <p className="font-17 text-cc-dark mb-3">
          You have successfully subscribed to a plan.
        </p>
      </div>
      <div>
          <button
            onClick={onNext}
            className="call-number btn btn-blue-full font-17"
          >
            Pay for a ride
          </button>
        </div>
    </>
  );
}

export default Success;
