const FreeRidePlansDetails = [
  {
    id: "Test plan",
    plan_amount: "499",
    discount: 0,
    package: "Click to see how it works",
    backgroundImage: "/assets/images/car-dark-1.png",
    oneOff: "true"
  },
  {
    id: "Basic plan",
    plan_amount: "5000",
    discount: 2.5,
    package: "Basic plan",
    backgroundImage: "/assets/images/car-dark-2.png",
  },
  {
    id: "standard plan",
    plan_amount: "10000",
    discount: 2.5,
    package: "Standard Plan",
    backgroundImage: "/assets/images/car-dark-3.png",
  },
  {
    id: "classic plan",
    plan_amount: "15000",
    discount: 2.5,
    package: "Classic Plan",
    backgroundImage: "/assets/images/car-dark-2.png",
  },
  {
    id: "compact plan",
    plan_amount: "20000",
    discount: 5,
    package: "Compact Plan",
    backgroundImage: "/assets/images/car-dark-1.png",
  },
  // {
  //   id: "premium plan",
  //   plan_amount: "50000",
  //   discount: 5,
  //   package: "Premium Plan",
  //   backgroundImage: "/assets/images/car-dark-3.png",
  // },
  // {
  //   id: "deluxe plan",
  //   plan_amount: "75000",
  //   discount: 5,
  //   package: "Deluxe Plan",
  //   backgroundImage: "/assets/images/car-dark-2.png",
  // },
  // {
  //   id: "executive plan",
  //   plan_amount: "100000",
  //   discount: 7.5,
  //   package: "Executive Plan",
  //   backgroundImage: "/assets/images/car-dark-1.png",
  // }
];
export default FreeRidePlansDetails;
