import React from "react";

function Options({ onNext, onBack }) {
  return (
    <>
      <div>
        <button
          style={{ marginBottom: "0px" }}
          className="back"
          type="button"
          onClick={onBack}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-angle-left"
            ></i>
          </span>
        </button>
      </div>

      <div className="pt-70">
        <p className="font-weight-bold text-deep-blue font-30 mb-2">
          How will you like to subscribe
        </p>
        <p className="text-cc-dark font-17 mb-2">Pay in full or pay later</p>
      </div>
      <div>
        <div className="row mt-4">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div
              onClick={() => onNext("not-registered")}
              data-wow-delay="0.1s"
              className="feature-box options-card mb-4 feature-box-rounded"
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <span className="font-30 mr-3 text-deep-blue">
                    <i className="fa-solid fa-car"></i>
                  </span>
                  <div>
                    <h3 className="position-relative font-17 mb-0 text-deep-blue">
                      Pay monthly
                    </h3>
                    <p className="font-17 mb-0 text-grey">
                      Make monthly payment for your plan
                    </p>
                  </div>
                </div>
                <div>
                  <span className="font-20 text-deep-blue">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </div>
              </div>
            </div>
            <div
              onClick={() => onNext("outright")}
              data-wow-delay="0.1s"
              className="feature-box options-card mb-4 feature-box-rounded"
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <span className="font-30 mr-3 text-deep-blue">
                    <i className="fa-solid fa-car"></i>
                  </span>
                  <div>
                    <h3 className="position-relative font-17 mb-0 text-deep-blue">
                      Subscribe in advance
                    </h3>
                    <p className="font-17 mb-0 text-grey">
                      Make full payment for your plan now
                    </p>
                  </div>
                </div>
                <div>
                  <span className="font-20 text-deep-blue">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Options;
