import React from 'react'

function WhatsappPrompt({ onBack }) {
    return (
        <>
            <div>
                <button style={{ marginBottom: "0px" }} className="back" type="button" onClick={onBack}>
                    <span aria-hidden="true">
                        <i style={{ fontSize: "1rem !important" }} className="fa-solid fa-angle-left"></i>
                    </span>
                </button>
            </div>

            <div className="pt-50">
                <img style={{ width: "350px", marginLeft: "-3rem" }} src="/assets/images/whatsapp-splash.jpg" alt="" />

                <p className="font-18 text-cc-dark mb-3 mt-3">
                    This would redirect you to whatsapp<br /> Do you want to proceed ?
                </p>
                <div className="d-flex justify-content-around flex-column align-items-center">
                    <div className='w-100'>
                        <a href="https://wa.me/09053535333?text=ride" target="_blank" rel="noreferrer" className="call-number btn btn-blue-full">
                            Continue to whatsapp
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhatsappPrompt;
