import React from "react";
import { useState } from "react";
import useSignupStore from "../../../store/signup.js";
import FormInput from "../../home/FormInput.jsx";

const services = [
  {
    id: "bolt",
    name: "Bolt",
    backgroundImage: "/assets/images/bolt-logo.png",
    integrated: true,
    disabled: false,
  },
  {
    id: "uber",
    name: "Uber",
    backgroundImage: "/assets/images/uber-logo.png",
    disabled: true,
  },
  {
    id: "inDriver",
    name: "InDriver",
    backgroundImage: "/assets/images/indriver-logo.png",
    disabled: true,
  }
];

function RideService({ onBack, onNext }) {
  const { data, updateData } = useSignupStore((state) => state);
  const [selected, setSelected] = useState(data?.rideService || []);
  const [showModal, setShowModal] = useState("");
  const [boltPhone, setBoltPhone] = useState(data?.bolt_phone || "");

  const handlePhoneSubmit = (service) => {
    if (service.integrated) {
      if (!boltPhone.length) return;
      setSelected([...selected, service.id]);
      setShowModal("");
    }
  };

  const handleClick = (service) => {
    if (!selected.includes(service.id)) {
      if (service.integrated) return setShowModal(service.id);
      setSelected([...selected, service.id]);
    } else {
      setSelected(selected.filter((s) => s !== service.id));
    }
  };

  const handleSubmit = () => {
    updateData({ bolt_phone: boltPhone });
    updateData({ rideService: selected });
    onNext();
  };

  const handleChange = (event) => {
    setBoltPhone(event.target.value);
  };
  return (
    <>
      <div>
        <div>
          <button
            style={{ marginBottom: "0px" }}
            className="back"
            type="button"
            onClick={onBack}
          >
            <span aria-hidden="true">
              <i
                style={{ fontSize: "1rem !important" }}
                className="fa-solid fa-angle-left"
              ></i>
            </span>
          </button>
        </div>
        <div className="pt-70 pb-4">
          <p className="redirect-text font-30 text-deep-blue font-weight-bold mb-1">
            Which ride services do you use?
          </p>
          <p className="text-cc-dark font-17">Please selecct a ride service</p>
        </div>
        <div className="container">
          <div className="row pb-4">
            {services.map((service) => (
              <div
                key={service.id}
                className={`col-6 col-lg-6 col-md-6 mb-3 ${
                  service.disabled && "ride-service-disabled"
                }`}
              >
                <div className="ride-logo-container">
                  {selected?.includes(service.id) && (
                    <div className="checked-bg">
                      <span>
                        <i className="fa-solid fa-check"></i>
                      </span>
                    </div>
                  )}
                  <img
                    onClick={() => handleClick(service)}
                    src={service.backgroundImage}
                    className="mb-3 ride-logo"
                    alt="logo"
                    width="100%"
                  />
                  {showModal === service.id && (
                    <div>
                      <div className="ride-contact">
                        <div className="position-relative">
                          <button
                            onClick={() => setShowModal("")}
                            className="x"
                            type="button"
                          >
                            <span aria-hidden="true">
                              <i className="fa-solid fa-x"></i>
                            </span>
                          </button>
                        </div>
                        <div className="pt-70 pb-5">
                          <p className="text-deep-blue font-30 font-weight-bold">
                            Kindly provide your registered {service.id} phone
                            number
                          </p>
                        </div>
                        <FormInput
                          type="text"
                          label="Bolt phone number"
                          value={boltPhone}
                          onChange={handleChange}
                        />
                        <button
                          onClick={() => handlePhoneSubmit(service)}
                          className="call-number btn btn-blue font-15"
                          disabled={!boltPhone}
                        >
                          Submit
                        </button>
                      </div>
                      <div
                        onClick={() => setShowModal("")}
                        className="main-overlay visible"
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          <button
            disabled={!selected.length}
            onClick={handleSubmit}
            className="call-number btn btn-blue"
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

export default RideService;
