import React, { useState } from "react";
import UserDetails from "./UserDetails";
import Success from "./Success";
import CheckEligibility from "./CheckEligibility";
import RideService from "./RideService";
import CancelRideRequest from "../CancelRideRequest";
import PendingRequest from "./PendingRequest";
import ActivePlan from "../ActivePlan";
import WhatsappPrompt from "../registered/WhatsappPrompt";
import LaunchPay from "./LaunchPay";

function Register({ onBack, onNext, onDone }) {
  const [view, setView] = useState("ride-service");

  return (
    <>
      {view === "ride-service" && (
        <RideService onBack={onBack} onNext={() => setView("user-details")} />
      )}
      {view === "user-details" && (
        <UserDetails
          onBack={() => setView("ride-service")}
          onNext={(v) => setView(v)}
        />
      )}
      {view === "pending-request" && (
        <PendingRequest
          onBack={() => setView("user-details")}
          onNext={(v) => setView(v)}
        />
      )}
      {view === "active-plan" && (
        <ActivePlan onBack={() => setView("user-details")} onDone={onDone} />
      )}
      {view === "cancel-ride-request" && (
        <CancelRideRequest
          onBack={() => setView("pending-request")}
          onNext={() => setView("check-eligibility")}
        />
      )}
      {view === "check-eligibility" && (
        <CheckEligibility
          onBack={() => setView("user-details")}
          onNext={() => setView("success")}
        />
      )}
      {view === "launchpay" && (
        <LaunchPay
          onBack={() => setView("user-details")}
          onNext={() => setView("success")}
        />
      )}
      {view === "success" && (
        <Success onNext={() => setView("whatsapp-prompt")} />
      )}
      {view === "whatsapp-prompt" && (
        <WhatsappPrompt onBack={() => setView("success")} />
      )}
    </>
  );
}

export default Register;
