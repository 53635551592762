import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useSignupStore from "../../../store/signup";
import RidePlansDetails from "../../../lib/ridePlansDetails";
import { numberWithCommas } from "../../../lib/utils";

function RidePlans({ onNext, handleToggle }) {
  const { updateData } = useSignupStore((state) => state);
  const handleClick = (plan) => {
    updateData({
      plan,
      currentView: "meal-plans",
    });
    onNext("plan-details");
  };
  return (
    <>
      <div className="position-relative">
        <button
          style={{ marginBottom: "0px" }}
          className="x sidebarCollapse"
          type="button"
          onClick={handleToggle}
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-x"
            ></i>
          </span>
        </button>
      </div>
      <div className="pt-70 pb-3">
        <p className="redirect-text font-30 text-deep-blue font-weight-bold mb-2">
          Subscribe now
        </p>
        <p className="text-cc-dark font-20 mb-2"> Please select a ride plan</p>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Swiper
            slidesPerView={1.5}
            spaceBetween={40}
            breakpoints={{
              0: {
                slidesPerView: 1.3,
                spaceBetween: 20,
              },
              470: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
              760: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
              1100: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
            }}
            className="showcase"
          >
            <div className="swiper-pagination"></div>
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {RidePlansDetails.map((plan) => (
                  <SwiperSlide key={plan.id}>
                    <div
                      onClick={() => handleClick(plan)}
                      data-wow-delay="0.1s"
                      className="feature-box food-card mb-4 feature-box-rounded"
                    >
                      <div
                        style={{
                          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.8), transparent), url(${plan.backgroundImage})`,
                          backgroundSize: "100%",
                          backgroundPosition: "right",
                        }}
                        className="inner-background"
                      ></div>
                      <div className="meal-plans">
                        <h3 className="position-relative ride-title">
                          ₦{numberWithCommas(plan.plan_amount)}
                          <span className={`sub-text ${plan.oneOff === "true" ? "d-none": ""}`}>|month</span>
                        </h3>
                        <p className="font-15">{plan.package}</p>
                        <button className="mt-3 font-15 d-flex call-number btn view-more">
                          View details
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            </div>
          </Swiper>
        </div>
      </div>
      <div className="pb-3 d-flex align-items-center justify-content-between">
        <p className="redirect-text font-20 text-cc-dark mb-1">
          Already have a plan ?
        </p>
        <button
          onClick={() => onNext("phone")}
          className="font-17 d-flex call-number btn btn-grey"
        >
          Ride now
        </button>
      </div>
    </>
  );
}

export default RidePlans;
