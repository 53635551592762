import React from 'react'
import useGlobalStore from '../../store/global'
import Tilt from "react-parallax-tilt";

function HowItWorks() {
    const toggleIsSignupOpen = useGlobalStore(
        (state) => state.toggleIsSignupOpen
      );
    
      return (
        <>
          <section id='howItWorks' className="overflow-hidden hero-two">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1
                    data-aos="zoom-in"
                    className="pb-3 text-center text-cc-dark title-small"
                  >
                    How it works
                  </h1>
                  <div className="row">
                    <Tilt style={{zIndex: "300"}}>
                      <div className="mb-5">
                        <div
                          style={{ position: "relative", zIndex: "9" }}
                          className="services-item"
                        >
                          <div className="services-wrap position-relative">
                            <div className="services-img">
                              <img
                                src="/assets/images/sedan.png"
                                className="mb-3"
                                alt="logo"
                              />
                            </div>
                            <div className="services-content">
                              <h2 className="title">Choose a ride</h2>
                              <div className="services-txt">
                                <p>
                                Any of the poular platforms, everywhere
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tilt>
    
                    <Tilt style={{zIndex: "200"}}>
                      <div className="mb-5">
                        <div className="services-item" data-tilt>
                          <div className="services-wrap position-relative">
                            <div className="services-img">
                              <img
                                src="/assets/images/hour-glass.png"
                                className="mb-3"
                                alt="logo"
                              />
                            </div>
                            <div className="services-content">
                              <h2 className="title">Eligibility check</h2>
                              <div className="services-txt">
                                <p>
                                We determine which plans you can really afford
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tilt>
    
                    <Tilt>
                      <div className="mb-5">
                        <div className="services-item" data-tilt>
                          <div className="services-wrap position-relative">
                            <div className="services-img">
                              <img
                                src="/assets/images/whatsapp.png"
                                className="mb-3"
                                alt="logo"
                              />
                            </div>
                            <div className="services-content">
                              <h2 className="title">Ride on whatsapp</h2>
                              <div className="services-txt">
                                <p>
                                No other app required, only the ride app and whatsapp
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tilt>
                  </div>
    
                  <div className="d-flex justify-content-center align-itmes center">
                    <button
                      onClick={toggleIsSignupOpen}
                      className="btn cta btn-cc mt-3 shadow-sm font-18"
                    >
                      {" "}
                      Get started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );
}

export default HowItWorks