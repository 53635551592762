import React, { useEffect, useState } from 'react'
import BoltPhone from '../free-ride-signup/BoltPhone';
import LinkedinLogin from '../free-ride-signup/LinkedinLogin';
import PendingRequest from '../free-ride-signup/PendingRequest';
import PlanDetails from '../free-ride-signup/PlanDetails';
import RidePlans from '../free-ride-signup/RidePlans';
import Success from '../free-ride-signup/Success';
import UserDetails from '../free-ride-signup/UserDetails';

function WhatsappSlideout({ isActive, handleToggle }) {
    const [view, setView] = useState("ride-plans");

    useEffect(() => {
        if (isActive) document.scrollingElement.style.overflow = "hidden"
        else document.scrollingElement.style.overflowY = "auto"
    }, [isActive])

    return (
        <>
            <div onClick={handleToggle} className={`main-overlay ${isActive === true ? 'visible' : ''}`}></div>
            <div className={`dotted-bg sidebar ${isActive ? 'active' : ''}`}>
            {
                view === "ride-plans" && (
                    <RidePlans onNext={() => setView("plan-details")} handleToggle={handleToggle}/>
                )
             }
             {
                view === "plan-details" && (
                    <PlanDetails onBack={() => setView("ride-plans")} onNext={() => setView("bolt-phone")} />
                )
             }
             {
                view === "bolt-phone" && (
                    <BoltPhone onBack={() => setView("plan-details")} onNext={() => setView("linkedin-login")}/>
                )
             }
             {
                view === "linkedin-login" && (
                    <LinkedinLogin onBack={() => setView("bolt-phone")} onNext={() => setView("user-details")}/>
                    
                )
             }
             {
                view === "user-details" && (
                    <UserDetails onBack={() => setView("linkedin-login")} onNext={(v) => setView(v)}/>
                )
             }
             {
                view === "pending-request" && (
                    <PendingRequest handleToggle={handleToggle}/>
                )
             }
             {
                view === "success" && (
                    <Success handleToggle={handleToggle}/>
                )
             }
            </div>
        </>
    )
}

export default WhatsappSlideout