import React from "react";
import { useQueryClient } from "react-query";
import useSignupStore from "../../store/signup.js";
import {
  useCancelRideRequestMutation,
  useCheckRideUserMutation,
  useCheckRideLoanRequestMutation,
} from "../../api/ride.js";

function CancelRideRequest({ onBack, onNext }) {
  const queryClient = useQueryClient();
  const { data, updateData } = useSignupStore((state) => state);
  const {
    mutateAsync: cancelRideRequest,
    isLoading: isCancelRideRequestLoading,
  } = useCancelRideRequestMutation();
  const { mutateAsync: checkRideUser, isLoading: isCheckRideUserLoading } =
    useCheckRideUserMutation();
  const {
    mutateAsync: checkRideLoanRequst,
    isLoading: isCheckRideLoanRequestLoading,
  } = useCheckRideLoanRequestMutation();

  const handleCancelRideRequest = async () => {
    try {
      await cancelRideRequest(data?.ride_data.id);
      await queryClient.clear();
      const payload = {
        name: data.user.name,
        phone: data.user.phone,
        email: data.user.email,
        bolt_phone: data?.bolt_phone,
      };
      const res = await checkRideUser(payload);
      const payload_two = {
        rider_id: res?.data?.data?.id,
        amount: data.plan.plan_amount,
        discount: data.plan.discount,
      };
      const res_two = await checkRideLoanRequst(payload_two);
      updateData({ ride_data: res_two.data.data });
      onNext();
    } catch (e) {
      console.log({ e });
    }
  };
  return (
    <>
      <div className="pt-70">
        <img
          style={{ height: "400px", width: "auto", marginLeft: "-3rem" }}
          src="/assets/images/Startled-bro.svg"
          alt=""
        />
        <p className="mb-1 redirect-text text-cc-dark font-weight-bold font-30 text-left mb-2">
          You are about to cancel this ride request.
        </p>
        <p className="font-17 text-cc-dark">Do you wish to continue?</p>
        <div className="d-flex align-items-center">
          <button
            onClick={() => handleCancelRideRequest()}
            className="d-flex align-items-center px-5 btn btn-outline-danger font-17 mr-4"
            disabled={
              isCancelRideRequestLoading ||
              isCheckRideUserLoading ||
              isCheckRideLoanRequestLoading
            }
          >
            {isCancelRideRequestLoading ||
            isCheckRideUserLoading ||
            isCheckRideLoanRequestLoading
              ? "Cancelling"
              : "Yes, cancel"}
            {(isCancelRideRequestLoading ||
              isCheckRideUserLoading ||
              isCheckRideLoanRequestLoading) && (
              <span className="ml-3 spin">
                <i className="fa-solid fa-spinner"></i>
              </span>
            )}
          </button>
          <button
            disabled={
              isCancelRideRequestLoading ||
              isCheckRideUserLoading ||
              isCheckRideLoanRequestLoading
            }
            onClick={onBack}
            className="px-5 btn btn-blue font-17"
          >
            No
          </button>
        </div>
      </div>
    </>
  );
}

export default CancelRideRequest;
