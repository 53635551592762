import React from "react";
import { numberWithCommas } from "../../../lib/utils.js";
import useSignupStore from "../../../store/signup.js";
import { useQueryClient } from "react-query";
import {
  useCancelRideRequestMutation,
  useCheckRideUserMutation,
  useCheckRideLoanRequestMutation,
} from "../../../api/ride.js";

function PendingRequest({ onBack, onNext}) {
  const { data, updateData } = useSignupStore((state) => state);
  const queryClient = useQueryClient();
  const {
    mutateAsync: cancelRideRequest,
    isLoading: isCancelRideRequestLoading,
  } = useCancelRideRequestMutation();
  const { mutateAsync: checkRideUser, isLoading: isCheckRideUserLoading } =
    useCheckRideUserMutation();
  const {
    mutateAsync: checkRideLoanRequst,
    isLoading: isCheckRideLoanRequestLoading,
  } = useCheckRideLoanRequestMutation();

  const handleCancelRideRequest = async () => {
    try {
      await cancelRideRequest(data?.ride_data.id);
      await queryClient.clear();
      const payload = {
        name: data.user.name,
        phone: data.user.phone,
        email: data.user.email,
        bolt_phone: data?.bolt_phone,
      };
      const res = await checkRideUser(payload);
      const payload_two = {
        rider_id: res?.data?.data?.id,
        amount: data.plan.plan_amount,
        discount: data.plan.discount,
      };
      const res_two = await checkRideLoanRequst(payload_two);
      updateData({ ride_data: res_two.data.data });
      if(res_two.data.data.amount === "3000") return onNext("launchpay")
      onNext("check-eligibility");
    } catch (e) {
      console.log({ e });
    }
  };
  const handleEligibility = () => {
    if (data.ride_data.amount === "3000") return onNext("launchpay")
    onNext("check-eligibility")
  }
  console.log(data)

  return (
    <>
      <div>
        <button
          style={{ marginBottom: "0px" }}
          className="back"
          type="button"
          onClick={onBack}
          disabled={
            isCancelRideRequestLoading ||
            isCheckRideUserLoading ||
            isCheckRideLoanRequestLoading
          }
        >
          <span aria-hidden="true">
            <i
              style={{ fontSize: "1rem !important" }}
              className="fa-solid fa-angle-left"
            ></i>
          </span>
        </button>
      </div>
      <div className="pt-70 pb-3">
        <h2 className="text-deep-blue font-weight-bold font-30 redirect-text">
          Another ride request already exists for this user.
        </h2>
        <p className="font-17 text-cc-dark">
          Do you want to continue with the existing request or cancel it and
          proceed with your current request ?
        </p>
      </div>
      <div className="font-17">
        <ul className="list-group text-cc-dark">
          <li className="list-group-item d-flex justify-content-between align-items-center">
            Customer name:
            <span className="font-weight-600 text-right">
              {data?.user.name}
            </span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            Amount:
            <span className="font-weight-600 text-right">
              ₦{numberWithCommas(data?.ride_data.amount)}
            </span>
          </li>
          {data?.ride_user?.bolt_phone && (
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Bolt phone number:
              <span className="font-weight-600 text-right">
                {data?.ride_user?.bolt_phone}
              </span>
            </li>
          )}
        </ul>
      </div>
      <div className="mt-4">
        <button
          onClick={handleCancelRideRequest}
          className="btn btn-blue-full mr-4 font-17 w-100 mb-2 d-flex justify-content-center"
          disabled={
            isCancelRideRequestLoading ||
            isCheckRideUserLoading ||
            isCheckRideLoanRequestLoading
          }
        >
          {isCancelRideRequestLoading ||
          isCheckRideUserLoading ||
          isCheckRideLoanRequestLoading
            ? "Please wait"
            : `Continue with ₦${numberWithCommas(data.plan.plan_amount)} plan`}
          {(isCancelRideRequestLoading ||
            isCheckRideUserLoading ||
            isCheckRideLoanRequestLoading) && (
            <span className="ml-3 spin">
              <i className="fa-solid fa-spinner"></i>
            </span>
          )}{" "}
        </button>
        <button
          onClick={handleEligibility}
          type="submit"
          className="btn btn-outline-gray font-17 w-100 opcacity-2"
          disabled={
            isCancelRideRequestLoading ||
            isCheckRideUserLoading ||
            isCheckRideLoanRequestLoading
          }
        >
            No, use this ₦{numberWithCommas(data?.ride_data?.amount)} instead
        </button>
      </div>
    </>
  );
}

export default PendingRequest;
